import React, { useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import Layout from '../../components/layout/Layout'
import SponsoredByBlock from '../../components/SponsoredByBlock'
import { Context } from '../../components/common/BaseContext'
import { getHomePageLists } from '../../state/home/selectors'
import SecondButton from '../../components/common/SecondButton'
import Input from '../../components/common/Input'
import { fetchLogJourneyAI } from '../../state/travel/actions'
import { saveCommuteInstanceToLocalstorage } from '../../utils/travel'

export default function LogTravelAIText() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { navbarHeight, screenWidth } = useContext(Context)
    const [t] = useTranslation('global')

    const { sponsorshipBlocks } = useSelector(getHomePageLists)
    const sponsoredBlock = sponsorshipBlocks.find((i) => i.siteSection === 'Log')
    const [loading, setLoading] = useState(false)
    const [previousUserInput, setPreviousUserInput] = useState<string | undefined>(undefined)
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const travelData = JSON.parse(localStorage.getItem('travelData') || '{}')

    const onSubmit = (value: {description: string}, actions: {resetForm: () => void}) => {
        setLoading(true)
        actions.resetForm()

        dispatch(fetchLogJourneyAI({
            text: value?.description,
            previousUserInput,
            errorMessage,
            dates: travelData?.date,
            games: travelData?.gameIds,
        }, (response) => {
            setLoading(false)
            navigate('/log/track-travel-overview', { state: { fromLogScreen: true, defaultTile: !response?.returnJourneyStartPlaceId ? 'one-way' : '' } })

            saveCommuteInstanceToLocalstorage(response, {
                // gameIds,
                // gameDate,
            }, {}, '')
        }, (error) => {
            console.log(error)
            if (error?.response?.data?.message) {
                setPreviousUserInput(error?.response?.data?.previousUserInput)
                setErrorMessage(error?.response?.data?.message)
                setLoading(false)
            }
        }))
    }

    return (
        <Layout showFooter={false}>
            <div className="w-full">
                {sponsoredBlock && <div className="mb-3 mobile:hidden"><SponsoredByBlock item={sponsoredBlock} /></div>}

                <Formik initialValues={{ description: '' }} onSubmit={onSubmit}>
                    <Form>
                        <div style={{ marginTop: screenWidth > 768 ? navbarHeight : 0 }}
                            className={`flex flex-col items-center ${screenWidth < 768 && 'pt-5'} mobile:px-[42px] px-4 h-screen`}>
                            <div className="max-w-[600px] w-full pb-[300px]">
                                <h1 className="font-bold text-22 text-cavolo tracking-tight">
                                    {t('log.describe-your-journey')}
                                </h1>

                                {errorMessage && (
                                    <p className="mt-3 text-raspberry">{errorMessage}</p>
                                )}

                                <Input
                                    type="textarea"
                                    name="description"
                                    className="h-28 mt-4 border border-avocado"
                                    component="textarea"
                                />

                                <h1 className="font-normal text-16 text-cavolo mt-[20px]">
                                    {t('log.use-your-own-words-to-describe-your-journey')}
                                </h1>

                                <h1 className="mt-4 text-16 text-cavolo font-normal">
                                    {t('log.try-and-be-as-precise-as-possible-for-example-say-i-walked-to-leeds-station-instead-of-i-went-to-the-station')}
                                </h1>

                                <SecondButton
                                    loading={loading}
                                    disabled={loading}
                                    text={t('global.submit')}
                                    className="mt-[55px] w-full h-[41px] font-semibold bg-raspberry rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200"
                                />
                            </div>
                        </div>
                    </Form>
                </Formik>
            </div>
        </Layout>
    )
}
