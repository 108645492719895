import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import DatePicker, { registerLocale } from 'react-datepicker'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import Button from '../../components/common/Button'
import 'react-datepicker/dist/react-datepicker.css'
import QuizQuitOverlayModal from '../../components/quiz/QuizQuitOverlayModal'
import { Colors } from '../../themes'
import { getUser } from '../../state/user/selectors'
import { fetchGame, fetchTransport, fetchTransportModes, fetchTravelContentBlock } from '../../state/travel/actions'
import Layout from '../../components/layout/Layout'
import { Context } from '../../components/common/BaseContext'
import SponsoredByBlock from '../../components/SponsoredByBlock'
import { getHomePageLists } from '../../state/home/selectors'
import { travelSelector } from '../../state/travel/selectors'
import { clearTravelData } from '../../utils/travel'
import { checkDateSequence, getDatesLocalization, travelDate } from '../../utils/date'
import ArticleModal from '../../components/article/ArticleModal'
import ContentBlockMini from '../../components/ContentBlockMini'
import { fetchSavedPlaces } from '../../state/user/actions'
import { Radio } from '../../components/common/Radio'
import DropdownLog from '../../components/common/DropDown'
import { MinusCounter, PlusCounter, QuestionIcon } from '../../components/common/Svg'
import InfoModal from '../../components/travel/InfoModal'
import { replacePartsOfText } from '../../utils/helpers'
import Switcher from '../../components/common/Switcher'
import { checkTravelDatesValid } from '../../state/travel/api'

type SelectedItem = {
    id: string | number;
    item: any;
};

export default function TrackTravelFan() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const today = new Date()
    const location = useLocation()
    const { user } = useSelector(getUser)
    const { gameList } = useSelector(travelSelector)
    const { isEmbedded, showArticleModal, setShowArticleModal } = useContext(Context)
    const { sponsorshipBlocks } = useSelector(getHomePageLists)
    const [startDate, setStartDate] = useState<Date>()
    const [buttonShow, setButtonShow] = useState<boolean>(false)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [selectedValues, setSelectedValues] = useState<SelectedItem[]>([])
    const ids = selectedValues.map((selectedItem) => selectedItem.id)
    const dates = selectedValues.map((selectedItem) => selectedItem?.item?.date)
    const [countOfPeople, setCountOfPeople] = useState<number>(Number(0))
    const [showQuizQuitOverlayModal, setShowQuizQuitOverlayModal] = useState<boolean>(false)
    const { travelContentBlock } = useSelector(travelSelector)
    const [t] = useTranslation('global')
    const [showModalCheckLimit, setShowModalCheckLimit] = useState<boolean | undefined>(false)
    const [showModalCheckEndLocation, setShowModalCheckEndLocation] = useState<boolean | undefined>(false)
    const [isChecked, setIsChecked] = useState<boolean>(false)
    const [errorModalTitle, setErrorModalTitle] = useState<string>('')

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked)
    }

    useEffect(() => {
        setShowModalCheckLimit(user?.company?.travelLimitForNumberOfEventsToLogAtOnce < selectedValues.length)
    }, [selectedValues.length, showModalCheckLimit, user?.company?.travelLimitForNumberOfEventsToLogAtOnce])

    const languageDataString = localStorage.getItem('language')
    const languageData = languageDataString ? JSON.parse(languageDataString) : 'en'

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [pathname])

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Track travel fan' })
    }, [])

    useEffect(() => {
        clearTravelData(dispatch)

        // eslint-disable-next-line
    }, [])

    const onRestartLoad = useCallback(() => {
        dispatch(fetchGame())
        dispatch(fetchTransport())
        dispatch(fetchTransportModes())
        dispatch(fetchTravelContentBlock())
        dispatch(fetchSavedPlaces)
        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        onRestartLoad()
        // eslint-disable-next-line
    }, [])

    const firstGameDate = gameList.find((i) => !i.isDisabled)
    const firstDate = new Date(user?.company?.isTravelTypeEvent ? (firstGameDate?.date || '') : today)
    const secondDate = new Date(travelDate(today))

    const onSubmit = async (isToday: boolean = false) => {
        const dateToday = new Date()
        const areAllPlacesSame = selectedValues.every((place) => place.item.place === selectedValues[0].item.place)

        if (!areAllPlacesSame) {
            setShowModalCheckEndLocation(true)
            return
        }

        if (!user?.company?.isTravelTypeEvent) {
            const e = await checkTravelDatesValid({ dates: [travelDate(isToday ? dateToday : startDate)] })

            if (e?.message) {
                setErrorModalTitle(t(e.message))
                return
            }
        }

        localStorage.setItem('travelData', JSON.stringify({
            date: user?.company?.isTravelTypeEvent ? dates : [travelDate(isToday ? dateToday : startDate)],
            gameDate: dates,
            gameIds: ids,
            legs: [],
            gameData: selectedValues,
            traveledWithOthersWhoDidntLogCount: countOfPeople,
        }))

        if (result?.isConsecutive && selectedValues?.length > 1 && isChecked) {
            navigate('/log/log-travel-date', { state: { isSwitched: isChecked, dateValues: selectedValues, firstDate, startDate, dates, ids, countOfPeople } })
        } else if (isChecked) {
            navigate('/log/log-travel-date', { state: { isSwitched: isChecked, dateValues: selectedValues, firstDate, startDate, dates, ids, countOfPeople } })
        } else {
            if (user.company?.travelLogByFavourite === 'Hidden' && user?.company?.travelLogByRecent === 'Hidden' && !user?.betaTesting) {
                navigate('/log/track-travel-start-end', { state: { prevUrl: location?.state?.prevUrl } })

                return
            }

            navigate('/log', { state: { prevUrl: location?.state?.prevUrl, isSwitched: isChecked } })
        }
    }

    const result = checkDateSequence(dates)

    const handleClick = async (e: any) => {
        if (languageData !== 'en') {
            const es = await import(`date-fns/locale/${languageData}`)
            registerLocale(languageData, es.default)
        }
        e.preventDefault()
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        if (result?.isConsecutive && selectedValues?.length > 1) {
            setIsChecked(true)
        } else {
            setIsChecked(false)
        }
    }, [result?.isConsecutive, selectedValues?.length])

    const handleChange = (e: any) => {
        setIsOpen(!isOpen)
        setStartDate(e)
        setButtonShow(true)
    }

    const disabledStyles = {
        backgroundColor: Colors.grey03,
        borderColor: Colors.grey03,
        color: Colors.grey02,
        boxShadow: '2px 2px 0px rgba(0, 0, 0, 0.15)',
        transitionDuration: '0.1s',
    }

    const sponsoredBlock = sponsorshipBlocks.find((i) => i.siteSection === 'Log')

    const [formattedDate, setFormattedDate] = useState<string | undefined>(undefined)

    useEffect(() => {
        const fetchData = async () => {
            if (startDate) {
                const formatted = await getDatesLocalization(startDate, languageData)
                setFormattedDate(formatted)
            }
        }
        fetchData()
        // eslint-disable-next-line
    }, [startDate])

    const handleRadioChange = (value: SelectedItem) => {
        setSelectedValues((prevValues) => (prevValues.some((val) => val.id === value.id)
            ? prevValues.filter((val) => val.id !== value.id)
            : [...prevValues, value]))
    }

    return (
        <Layout showFooter={false} spaceOnBottom={!isEmbedded} maxWidth={false} travel>
            <div className="w-full">
                {sponsoredBlock && <div className="mb-3 mobile:hidden"><SponsoredByBlock item={sponsoredBlock} /></div>}

                <div className="flex flex-col items-center mobile:pt-[54px] pt-5 mobile:px-[42px] px-[16px] h-screen">
                    <div className="max-w-[600px] w-full pb-[300px]">
                        {travelContentBlock.title && (
                            <ContentBlockMini />
                        )}

                        <h1 className="heading-uppercase heading-font heading-font text-22 tracking-tight font-semibold text-cavolo mt-[63px]">
                            {t('log.log-a-journey')}
                        </h1>

                        {user?.company?.isTravelTypeEvent ? (
                        // <h1 className="line text-16 tracking-tight font-medium not-heading-font text-cavolo mt-[24px]">
                        //     {t('log.which-game-would-you-like-to-log-travel-for').replace('[game]', user?.company.travelTrackingType === 'standard' ? 'game' : user.company.travelEventName)}
                        // </h1>

                            <h1 className="line text-16 tracking-tight font-medium not-heading-font text-cavolo mt-6">
                                {t('log.which-game(s)-would-you-like-to-log-travel-for').replace('[game]', user.company.travelEventNameOptionalPlural || '')}
                            </h1>
                        ) : (
                            <h1 className="text-16 tracking-tight font-medium not-heading-font text-cavolo mt-[27px]">
                                {t('log.when-did-you-travel?')}
                            </h1>
                        )}

                        {startDate && (
                            <h1 className="my-[10px] text-22 text-black font-bold not-heading-font">
                                {formattedDate}
                            </h1>
                        )}

                        {user?.company?.isTravelTypeEvent ? (
                            <div>
                                {/* <div className="mt-[7px]"> */}
                                {/*    <Dropdown handleSelectChange={handleSelectChange} /> */}
                                {/* </div> */}

                                <div className={`mt-5 max-h-[200px] ${gameList.length > 5 && 'overflow-y-auto'}`}>
                                    {gameList.filter((i) => !i.isDisabled)?.map((item) => (
                                        <Radio
                                            inputClassName="mb-4 tablet:mb-[20px]"
                                            key={item.id}
                                            label={item.name}
                                            value={item.id}
                                            labelClassName="text-grey01 font-normal tablet:text-16 text-14 mb-4 tablet:mb-[20px]"
                                            checked={selectedValues.some((val) => val.id === item.id)}
                                            onChange={() => handleRadioChange({ id: item.id, item })}
                                            checkBox
                                            date={item.date}
                                        />
                                    ))}
                                </div>

                                <Switcher isChecked={isChecked} handleCheckboxChange={handleCheckboxChange} title={t('log.i-stayed-away-from-home-overnight-on-this-trip')} />

                                <div className="mt-5">
                                    <DropdownLog
                                        openOrClose
                                        title={firstDate < secondDate ? t('log.travelled-with-others-who-didn\'t-log') : t('log.FUTURE|travelled-with-others-who-didn\'t-log')}
                                        body={(
                                            <div className="mt-2.5">
                                                <div className="flex items-center justify-between cursor-pointer">
                                                    <h1 className="font-normal text-14 text-cavolo">
                                                        {firstDate < secondDate
                                                            ? t('log.how-many-people-made-this-journey-with-you-(not-including-yourself)')
                                                            : t('log.FUTURE|how-many-people-made-this-journey-with-you-(not-including-yourself)')}
                                                    </h1>

                                                    <div>
                                                        <QuestionIcon />
                                                    </div>
                                                </div>

                                                <div className="flex items-center justify-center mb-6 mt-3">
                                                    <button
                                                        onClick={() => countOfPeople > 0 && setCountOfPeople(countOfPeople - 1)}
                                                        className="flex items-center justify-center w-[28px] h-[28px] bg-spinach rounded-full cursor-pointer">
                                                        <MinusCounter />
                                                    </button>

                                                    <div
                                                        className="flex items-center justify-center w-[146px] h-[48px] border-2 border-spinach rounded-lg mx-[15px]">
                                                        <h1 className="text-14 font-normal text-black tracking-tight">
                                                            {countOfPeople}
                                                        </h1>
                                                    </div>

                                                    <button onClick={() => setCountOfPeople(countOfPeople + 1)}
                                                        className="flex items-center justify-center w-[28px] h-[28px] bg-spinach rounded-full cursor-pointer">
                                                        <PlusCounter />
                                                    </button>
                                                </div>
                                            </div>
                                        )} />
                                </div>
                            </div>
                        ) : (
                            <div>
                                <Button
                                    type="button"
                                    className="bottom-8 duration-100 mt-[7px]"
                                    text={t('log.today')}
                                    onClick={() => onSubmit(true)} />

                                <Button
                                    onClick={handleClick}
                                    type="button"
                                    className="bottom-8 bg-pistachio duration-100 border-spinach text-cavolo"
                                    text={t('log.select-another-date')} />

                                {/* <Button */}
                                {/*    onClick={() => navigate('/log/log-travel-date', { state: { dateValues: selectedValues, firstDate, secondDate } })} */}
                                {/*    type="button" */}
                                {/*    className="bottom-8 bg-pistachio duration-100 border-spinach text-cavolo" */}
                                {/*    text={t('log.multi-day-trip')} /> */}
                            </div>
                        )}

                        {isOpen && (
                            <div className="flex justify-center mt-4">
                                <DatePicker
                                    selected={startDate}
                                    onChange={handleChange}
                                    inline
                                    dateFormat="dd/mm/yyyy"
                                    locale={languageData}
                                    minDate={user?.company?.earliestLoggingDateAllowed ? new Date(user?.company?.earliestLoggingDateAllowed) : undefined}
                                    maxDate={user?.company?.latestLoggingDateAllowed ? new Date(user?.company?.latestLoggingDateAllowed) : undefined}
                                />
                            </div>
                        )}
                    </div>
                </div>

                {(buttonShow || user?.company?.isTravelTypeEvent) && (
                    <div
                        className={`fixed bottom-0 ${isEmbedded ? 'h-[120px]' : 'h-[112px]'} bg-pistachio w-full duration-100`}
                        style={{ boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.15)' }}>
                        <div
                            className="mx-auto flex max-mobile:flex-col max-w-[848px] relative h-full px-[16px] mobile:px-[42px]">
                            <div className="flex-1 mt-7 mobile:mr-[40px] mr-[16px]" />

                            <div className={`${isEmbedded ? 'mb-4' : ''} mobile:ml-8 mobile:w-[180px] relative`}>
                                {user?.company?.isTravelTypeEvent ? (
                                    <Button
                                        onClick={() => onSubmit()}
                                        disabled={selectedValues.length < 1 || user?.company?.travelLimitForNumberOfEventsToLogAtOnce < selectedValues.length}
                                        style={selectedValues.length < 1 || user?.company?.travelLimitForNumberOfEventsToLogAtOnce < selectedValues.length ? disabledStyles : undefined}
                                        className="absolute bottom-8 duration-100 not-heading-font"
                                        text={t('global.continue')} />
                                ) : (
                                    <Button
                                        onClick={() => onSubmit()}
                                        disabled={!user?.company?.isTravelTypeEvent && startDate === null}
                                        style={!user?.company?.isTravelTypeEvent && startDate === null ? disabledStyles : undefined}
                                        className="absolute bottom-8 duration-100 not-heading-font"
                                        text={t('global.continue')} />
                                )}
                            </div>
                        </div>
                    </div>
                )}

                <QuizQuitOverlayModal
                    description={user?.company?.gamification ? t('log.quit-description-is-switched-on-gamification') : t('log.quit-description-is-switched-off-gamification')}
                    isShown={showQuizQuitOverlayModal}
                    onClose={() => navigate(location?.state.prevUrl || '/')}
                    onContinue={() => setShowQuizQuitOverlayModal(false)} />

                {showArticleModal && showArticleModal === travelContentBlock?.overlayArticle ? (
                    <ArticleModal setShowModal={setShowArticleModal} articleId={travelContentBlock?.overlayArticle} />
                ) : undefined}

                <InfoModal
                    isShown={showModalCheckLimit}
                    onClose={() => setShowModalCheckLimit(false)}
                    setSelectedValues={setSelectedValues}
                    title={replacePartsOfText(t('log.you-are-only-allowed-to-log-N-games-at-once'), {
                        '[N]': String(user.company.travelLimitForNumberOfEventsToLogAtOnce),
                        '[games]': user.company.travelEventNamePlural || '',
                    })}
                />

                <InfoModal
                    isShown={!!errorModalTitle}
                    onClose={() => setErrorModalTitle('')}
                    title={errorModalTitle}
                />

                <InfoModal
                    isShown={showModalCheckEndLocation}
                    onClose={() => setShowModalCheckEndLocation(false)}
                    setSelectedValues={setSelectedValues}
                    title={t('log.to-log-multiple-event-name-plural-at-once-they-must-all-have-the-same-end-location').replace('[event-name-plural]', user.company.travelEventNamePlural || '')}
                />
            </div>
        </Layout>
    )
}
