import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import ReactGA from 'react-ga4'
import { motion } from 'framer-motion'
import Layout from '../../components/layout/Layout'
import { Context } from '../../components/common/BaseContext'
import { getUser } from '../../state/user/selectors'
import { ChevronUp } from '../../components/common/Svg'
import SponsoredByBlock from '../../components/SponsoredByBlock'
import { getHomePageLists } from '../../state/home/selectors'
import QuizQuitOverlayModal from '../../components/quiz/QuizQuitOverlayModal'
import { commuteSaveList } from '../../state/travel/selectors'
import { getDate } from '../../utils/date'
import ShowWithAnimation from '../../components/ShowWithAnimation'
import { postTravelCommute, removeOrOffset } from '../../state/travel/actions'
import RemoveYourEmissionsModal from '../../components/travel/RemoveYourEmissionsModal'
import OffsetYourEmissionsModal from '../../components/travel/OffsetYourEmissionsModal'
import ImpactBar from '../../components/travel/ImpactBar'
import Button from '../../components/common/Button'
import { PlanJourneyTransitItem } from '../PlanJourneyResult'
import { convertSecondsToMinutesAndHours, readableCO2 } from '../../utils/helpers'
import PledgeSuccessModal from '../../components/travel/PledgeSuccessModal'
import { clearTravelData, getPlaceIdsForSteps } from '../../utils/travel'
import TicketModal from '../../components/common/TicketModal'
import { fetchWinnersPrice } from '../../state/prize/actions'
import { fetchUser } from '../../state/user/actions'
import { getPrize } from '../../state/prize/selectors'

export default function YourImpact() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useSelector(getUser)
    const { isEmbedded, pledgeUrls, screenWidth, navbarHeight, setTravelPointModalPoints } = useContext(Context)
    const [t] = useTranslation('global')
    const { pathname } = useLocation()
    const { prizeDraw } = useSelector(getPrize)
    const { commuteSave } = useSelector(commuteSaveList)
    const [outboundLegsEndPlaceNames, setOutboundLegsEndPlaceNames] = useState<any>({})
    const [returnLegsEndPlaceNames, setReturnLegsEndPlaceNames] = useState<any>({})

    const [showModal, setShowModal] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const transportCO2e = (commuteSave?.outboundTransitJourney?.ceo2Kg || 0) + (commuteSave?.returnTransitJourney?.ceo2Kg || 0)
    const durationPublicTransport = (commuteSave?.outboundTransitJourney?.durationSeconds || 0) + (commuteSave?.returnTransitJourney?.durationSeconds || 0)
    const totalCeo2Kg = commuteSave?.totalCeo2Kg || 0

    const averageCeo2Kg = (commuteSave?.totalCeo2Kg === commuteSave?.averageCeo2Kg ? 0.001 : 0) + (commuteSave?.averageCeo2Kg || 0)
    const savesCO2e = 100 - (transportCO2e * 100) / totalCeo2Kg

    const yourEmissionByKg = (totalCeo2Kg - transportCO2e).toFixed(0)

    const yourFootprint = parseFloat(readableCO2(totalCeo2Kg) as string)
    const eventAverage = parseFloat(readableCO2(averageCeo2Kg) as string)

    const maxBarWidth = 95
    let yourFootprintWidth; let
        eventAverageWidth

    if (yourFootprint === 0) {
        yourFootprintWidth = '0%'
        eventAverageWidth = `${maxBarWidth}%`
    } else if (yourFootprint > eventAverage) {
        yourFootprintWidth = `${maxBarWidth}%`
        eventAverageWidth = `${(eventAverage / yourFootprint) * maxBarWidth}%`
    } else {
        eventAverageWidth = `${maxBarWidth}%`
        yourFootprintWidth = `${(yourFootprint / eventAverage) * maxBarWidth}%`
    }

    // const maxCO2e = Math.max(totalCeo2Kg, averageCeo2Kg, transportCO2e)
    // const publicTransportWidth = `${(transportCO2e / maxCO2e) * maxBarWidth}%`
    const publicTransportWidth = '60%'

    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false)
    const [showOffsetModal, setShowOffsetModal] = useState<boolean>(false)
    const [showQuizQuitOverlayModal, setShowQuizQuitOverlayModal] = useState<boolean>(false)
    const [isDetailsShown, setIsDetailsShown] = useState<boolean>(false)
    const [firstTicketModal, setFirstTicketModal] = useState<boolean>(false)

    const percentageValue = parseFloat(publicTransportWidth.replace('%', ''))

    const pledgeButton = JSON.parse(localStorage.getItem('pledgeButton') || '""')

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [pathname])

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Your impact' })
    }, [])

    const { sponsorshipBlocks } = useSelector(getHomePageLists)
    const sponsoredBlock = sponsorshipBlocks.find((i) => i.siteSection === 'Log')

    const onSubmit = () => {
        // if (!user?.company?.prizeDrawEntryAtTheEndOfTravelLogging) {
        if (user?.company?.singleUseDataCollection === 'none' || !user?.company?.singleUseDataCollection) {
            dispatch(fetchWinnersPrice)
            dispatch(fetchUser)

            if (!prizeDraw?.id) {
                setFirstTicketModal(true)
                clearTravelData(dispatch)
            } else {
                setTravelPointModalPoints(commuteSave.points || 0)
                clearTravelData(dispatch)
                if (user?.company?.anonymousMode) {
                    navigate('/')
                    return
                }

                navigate('/me/impact')
            }
        } else {
            navigate('/log/prize-draw')
        }
    }

    const onRemove = () => {
        setShowRemoveModal(true)

        dispatch(removeOrOffset({
            commute: commuteSave?.id,
            removeStatus: 'clicked',
        }))
    }

    const onOffset = () => {
        setShowOffsetModal(true)

        dispatch(removeOrOffset({
            commute: commuteSave?.id,
            offsetStatus: 'clicked',
        }))
    }

    const onSubmitPledge = async () => {
        setLoading(true)

        try {
            await new Promise<void>((resolve) => {
                getPlaceIdsForSteps([...commuteSave.outboundTransitJourney.legs, ...commuteSave.returnTransitJourney.legs]).then((placeIds) => {
                    const outboundLegs = commuteSave.outboundTransitJourney.legs.map((item, index) => {
                        const legStartPlaceIds = placeIds.filter((i) => i.startLocation === item.startLocation)[0]

                        return {
                            transport: item?.transportId,
                            isGoingBack: false,

                            startPlaceId: legStartPlaceIds.startPlaceId,
                            startPlaceName: index === 0 ? commuteSave.startPlaceName : String(outboundLegsEndPlaceNames[String(index - 1)] || item.startLocation.placeName),
                            startPlaceLon: item.startLocation.latLng.longitude,
                            startPlaceLat: item.startLocation.latLng.latitude,

                            endPlaceId: legStartPlaceIds.endPlaceId,
                            endPlaceName: commuteSave.outboundTransitJourney.legs.length === index + 1 ? commuteSave.endPlaceName : outboundLegsEndPlaceNames[String(index)] || item.endLocation.placeName,
                            endPlaceLon: item.startLocation.latLng.longitude,
                            endPlaceLat: item.startLocation.latLng.latitude,

                            legPolyline: item.polyline.encodedPolyline,

                            position: index + 1,
                        }
                    })

                    const returnLegs = commuteSave.returnTransitJourney.legs.map((item, index) => {
                        const legStartPlaceIds = placeIds.filter((i) => i.startLocation === item.startLocation)[0]

                        return {
                            transport: item?.transportId,
                            isGoingBack: true,

                            startPlaceId: legStartPlaceIds.startPlaceId,
                            startPlaceName: index === 0 ? commuteSave.returnJourneyStartPlaceName : String(returnLegsEndPlaceNames[String(index - 1)] || item.startLocation.placeName),
                            startPlaceLon: item.startLocation.latLng.longitude,
                            startPlaceLat: item.startLocation.latLng.latitude,

                            endPlaceId: legStartPlaceIds.endPlaceId,
                            endPlaceName: commuteSave.returnTransitJourney.legs.length === index + 1 ? commuteSave.returnJourneyEndPlaceName : returnLegsEndPlaceNames[String(index)] || item.endLocation.placeName,
                            endPlaceLon: item.startLocation.latLng.longitude,
                            endPlaceLat: item.startLocation.latLng.latitude,

                            legPolyline: item.polyline.encodedPolyline,

                            position: index + 1,
                        }
                    })

                    const legs = [...outboundLegs, returnLegs]

                    dispatch(postTravelCommute({
                        startPlaceId: commuteSave?.startPlaceId,
                        startPlaceName: commuteSave?.startPlaceName,
                        startPlaceLon: commuteSave?.startPlaceLon,
                        startPlaceLat: commuteSave?.startPlaceLat,

                        endPlaceId: commuteSave?.endPlaceId,
                        endPlaceName: commuteSave?.endPlaceName,
                        endPlaceLon: commuteSave?.endPlaceLon,
                        endPlaceLat: commuteSave?.endPlaceLat,

                        returnJourneyStartPlaceId: commuteSave?.returnJourneyStartPlaceId,
                        returnJourneyStartPlaceName: commuteSave?.returnJourneyStartPlaceName,
                        returnJourneyStartPlaceLon: commuteSave?.returnJourneyStartPlaceLon,
                        returnJourneyStartPlaceLat: commuteSave?.returnJourneyStartPlaceLat,

                        returnJourneyEndPlaceId: commuteSave?.returnJourneyEndPlaceId,
                        returnJourneyEndPlaceName: commuteSave?.returnJourneyEndPlaceName,
                        returnJourneyEndPlaceLon: commuteSave?.returnJourneyEndPlaceLon,
                        returnJourneyEndPlaceLat: commuteSave?.returnJourneyEndPlaceLat,

                        game: commuteSave?.game?.id,
                        journeyTypeId: commuteSave?.journeyType || undefined,
                        relatedJourney: commuteSave?.id,
                        legs,
                    }, () => {
                        onSubmit()
                        localStorage.setItem('pledgeButton', JSON.stringify(true))
                        setShowModal(false)
                        localStorage.removeItem('pledgeButton')
                        resolve()
                    }))
                })
            })
        } catch (error) {
            console.error('Error submitting pledge:', error)
        }

        setLoading(false)
    }

    const onSubmitPledgeToTake = async () => {
        setLoading(true)

        try {
            await new Promise<void>((resolve) => {
                getPlaceIdsForSteps([...commuteSave.outboundTransitJourney.legs, ...commuteSave.returnTransitJourney.legs]).then((placeIds) => {
                    const outboundLegs = commuteSave.outboundTransitJourney.legs.map((item, index) => {
                        const legStartPlaceIds = placeIds.filter((i) => i.startLocation === item.startLocation)[0]

                        return {
                            transport: item?.transportId,
                            isGoingBack: false,

                            startPlaceId: legStartPlaceIds.startPlaceId,
                            startPlaceName: index === 0 ? commuteSave.startPlaceName : String(outboundLegsEndPlaceNames[String(index - 1)] || item.startLocation.placeName),
                            startPlaceLon: item.startLocation.latLng.longitude,
                            startPlaceLat: item.startLocation.latLng.latitude,

                            endPlaceId: legStartPlaceIds.endPlaceId,
                            endPlaceName: commuteSave.outboundTransitJourney.legs.length === index + 1 ? commuteSave.endPlaceName : outboundLegsEndPlaceNames[String(index)] || item.endLocation.placeName,
                            endPlaceLon: item.startLocation.latLng.longitude,
                            endPlaceLat: item.startLocation.latLng.latitude,

                            legPolyline: item.polyline.encodedPolyline,

                            position: index + 1,
                        }
                    })

                    const returnLegs = commuteSave.returnTransitJourney.legs.map((item, index) => {
                        const legStartPlaceIds = placeIds.filter((i) => i.startLocation === item.startLocation)[0]

                        return {
                            transport: item?.transportId,
                            isGoingBack: true,

                            startPlaceId: legStartPlaceIds.startPlaceId,
                            startPlaceName: index === 0 ? commuteSave.returnJourneyStartPlaceName : String(returnLegsEndPlaceNames[String(index - 1)] || item.startLocation.placeName),
                            startPlaceLon: item.startLocation.latLng.longitude,
                            startPlaceLat: item.startLocation.latLng.latitude,

                            endPlaceId: legStartPlaceIds.endPlaceId,
                            endPlaceName: commuteSave.returnTransitJourney.legs.length === index + 1 ? commuteSave.returnJourneyEndPlaceName : returnLegsEndPlaceNames[String(index)] || item.endLocation.placeName,
                            endPlaceLon: item.startLocation.latLng.longitude,
                            endPlaceLat: item.startLocation.latLng.latitude,

                            legPolyline: item.polyline.encodedPolyline,

                            position: index + 1,
                        }
                    })

                    const legs = [...outboundLegs, ...returnLegs]

                    dispatch(postTravelCommute({
                        startPlaceId: commuteSave?.startPlaceId,
                        startPlaceName: commuteSave?.startPlaceName,
                        startPlaceLon: commuteSave?.startPlaceLon,
                        startPlaceLat: commuteSave?.startPlaceLat,

                        endPlaceId: commuteSave?.endPlaceId,
                        endPlaceName: commuteSave?.endPlaceName,
                        endPlaceLon: commuteSave?.endPlaceLon,
                        endPlaceLat: commuteSave?.endPlaceLat,

                        returnJourneyStartPlaceId: commuteSave?.returnJourneyStartPlaceId,
                        returnJourneyStartPlaceName: commuteSave?.returnJourneyStartPlaceName,
                        returnJourneyStartPlaceLon: commuteSave?.returnJourneyStartPlaceLon,
                        returnJourneyStartPlaceLat: commuteSave?.returnJourneyStartPlaceLat,

                        returnJourneyEndPlaceId: commuteSave?.returnJourneyEndPlaceId,
                        returnJourneyEndPlaceName: commuteSave?.returnJourneyEndPlaceName,
                        returnJourneyEndPlaceLon: commuteSave?.returnJourneyEndPlaceLon,
                        returnJourneyEndPlaceLat: commuteSave?.returnJourneyEndPlaceLat,

                        game: commuteSave?.game?.id,
                        journeyTypeId: commuteSave?.journeyType || undefined,
                        relatedJourney: commuteSave?.id,
                        legs,
                    }, () => {
                        resolve()
                    }))
                })
            })
        } catch (error) {
            console.error('Error submitting pledge:', error)
        }

        setLoading(false)
    }

    function capitalizeFirstLetter(text: string | undefined | null) {
        return text ? text.charAt(0).toUpperCase() + text.slice(1) : ''
    }

    return (
        <Layout showFooter={false} maxWidth={false}>
            <div className="w-full min-h-screen">
                <div>
                    <div style={{ marginTop: navbarHeight, left: '50%', transform: 'translateX(-50%)' }}
                        className="fixed top-0 left-0 w-full z-10 bg-white max-w-[1440px]">

                        {sponsoredBlock && <div className="mobile:hidden"><SponsoredByBlock item={sponsoredBlock} /></div>}
                        {/* <div className="flex justify-end items-center h-[53px] pr-2 mobile:mx-[42px] mx-4"> */}
                        {/*    <div onClick={() => setShowQuizQuitOverlayModal(true)} */}
                        {/*        className="flex justify-end cursor-pointer"> */}
                        {/*        <DismissIcon width={18} height={18} /> */}
                        {/*    </div> */}
                        {/* </div> */}
                    </div>

                    <div style={{ marginTop: screenWidth > 768 ? navbarHeight : !sponsoredBlock ? navbarHeight : 125 }} className="h-screen flex flex-col items-center mobile:px-[42px] px-4">
                        <div className="max-w-[600px] w-full mt-2 pb-[300px]">
                            <h1 className="heading-uppercase heading-font text-cavolo text-22 tracking-tight font-semibold">
                                {t('me.impact')}
                            </h1>

                            <h1 className="text-grey01 text-14">
                                {commuteSave?.startPlaceName?.slice(0, commuteSave?.startPlaceName?.indexOf(','))} {t('global.to')} {commuteSave?.endPlaceName?.slice(0, commuteSave?.endPlaceName?.indexOf(','))}, {t('yourImpact.return')}, {commuteSave?.game?.date ? getDate(commuteSave?.game?.date || '') : getDate(String(commuteSave?.dates))}
                            </h1>

                            <ImpactBar
                                label={t('yourImpact.your-footprint')}
                                value={`${yourFootprint}kg CO2e`}
                                width={yourFootprintWidth}
                                color="bg-lime"
                                labelColor="cavolo"
                            />

                            <ImpactBar
                                label={user?.company?.travelTrackingType === 'standard' ? t('yourImpact.event-average').replace('[Event]', t('yourImpact.organisation')) : t('yourImpact.event-average').replace('[Event]', capitalizeFirstLetter(user?.company?.travelEventName || 'Event'))}
                                value={`${eventAverage}kg CO2e`}
                                width={eventAverageWidth}
                                color="bg-mint"
                                labelColor="marrow"
                            />

                            {yourFootprint !== 0 && commuteSave.showPublicTransportBar && commuteSave.outboundTransitJourney?.legs?.length ? (
                                <div>
                                    <h1 className="text-14 font-normal text-cavolo tracking-tight mt-5">
                                        {t('yourImpact.if-you-took-public-transport')}
                                    </h1>

                                    <div className="flex items-center">
                                        <motion.div
                                            className="flex items-center justify-end h-[34px] bg-spinach w-[20%] mt-1"
                                            initial={{ width: 0 }}
                                            animate={{ width: publicTransportWidth }}
                                            transition={{ duration: 0.8 }}
                                        >
                                            {percentageValue > 40 && (
                                                <motion.span
                                                    initial={{ opacity: 0 }}
                                                    animate={{ opacity: 1 }}
                                                    transition={{ delay: 0.7, duration: 0.7 }}
                                                >
                                                    {screenWidth > 560 ? (
                                                        <div className="max-xs-mobile:text-12 text-14 font-normal text-savoy tracking-tight mr-1.5">
                                                            {readableCO2(transportCO2e)}kg CO2e, {convertSecondsToMinutesAndHours(durationPublicTransport)}
                                                        </div>
                                                    ) : (
                                                        <div className="max-xs-max:text-12 text-14 font-normal text-savoy tracking-tight mr-1.5">
                                                            <div>{readableCO2(transportCO2e)}kg CO2e,</div>
                                                            <div>{convertSecondsToMinutesAndHours(durationPublicTransport)}</div>
                                                        </div>
                                                    )}
                                                </motion.span>
                                            )}

                                            {percentageValue > 50 && (
                                                <motion.div
                                                    className="h-[24px] bg-raspberry px-1.5 mr-1.5 rounded"
                                                    initial={{ opacity: 0 }}
                                                    animate={{ opacity: 1 }}
                                                    transition={{ delay: 0.6, duration: 0.6 }}
                                                >
                                                    <span className="max-x-mobile:text-12 text-14 font-normal text-cavolo tracking-tight">
                                                        {screenWidth > 400 ? t('yourImpact.saves') : '-'} {savesCO2e.toFixed(0)}% CO2e
                                                    </span>
                                                </motion.div>
                                            )}
                                        </motion.div>

                                        {percentageValue < 40 && (
                                            <div className="flex flex-wrap max-w-full max-xs-max:text-12 max-xs-mobile:text-10 text-14 font-normal text-cavolo tracking-tight ml-1.5">
                                                <span>{readableCO2(transportCO2e)}kg CO2e,&nbsp;</span>

                                                <span className="whitespace-nowrap">
                                                    {convertSecondsToMinutesAndHours(durationPublicTransport)}
                                                </span>
                                            </div>
                                        )}

                                        {percentageValue < 50 && (
                                            <motion.div
                                                className="h-[24px] bg-raspberry ml-3.5 px-1.5 rounded"
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                transition={{ delay: 0.7, duration: 0.7 }}
                                            >
                                                <span className="max-x-mobile:text-12 text-14 font-normal text-cavolo tracking-tight">
                                                    {screenWidth > 386 ? t('yourImpact.saves') : ''} {savesCO2e.toFixed(0)}% CO2e
                                                </span>
                                            </motion.div>
                                        )}
                                    </div>

                                    <div onClick={() => setIsDetailsShown(!isDetailsShown)}
                                        className="flex items-center cursor-pointer mt-3">
                                        <div className="mr-2.5 text-14 font-normal text-cavolo tracking-tight">
                                            {isDetailsShown ? (
                                                t('yourImpact.show-less')
                                            ) : (
                                                t('yourImpact.show-me-how')
                                            )}
                                        </div>

                                        <ChevronUp style={{
                                            transform: isDetailsShown ? 'rotate(180deg)' : undefined,
                                            transition: 'transform 0.2s ease',
                                        }} />
                                    </div>

                                    <ShowWithAnimation isShown={isDetailsShown}>
                                        <div className="flex justify-between">
                                            {commuteSave?.game && (
                                                commuteSave?.game?.isFuture ? (
                                                    !pledgeButton && (
                                                        <div
                                                            onClick={() => setShowModal(true)}
                                                            className="flex items-center justify-center w-[48%] bg-pistachio border border-spinach rounded mt-3.5 cursor-pointer">
                                                            <span style={{ letterSpacing: -0.24 }}
                                                                className="text-13 font-bold text-cavolo px-2 py-2.5 text-center not-heading-font">
                                                                {t('yourImpact.log-this-route-instead')}
                                                            </span>
                                                        </div>
                                                    )
                                                ) : (
                                                    <div
                                                        onClick={() => {
                                                            setShowModal(true)
                                                            onSubmitPledgeToTake()
                                                        }}
                                                        className="flex items-center justify-center w-[48%] bg-pistachio border border-spinach rounded mt-3.5 cursor-pointer">
                                                        <span style={{ letterSpacing: -0.24 }}
                                                            className="text-13 font-bold text-cavolo px-2 py-2.5 text-center not-heading-font">
                                                            {t('yourImpact.pledge-to-take-this-route-next-time')}
                                                        </span>
                                                    </div>
                                                )
                                            )}

                                            {pledgeUrls && (
                                                <div
                                                    onClick={() => navigate('/lower/pledges')}
                                                    className="flex items-center justify-center w-[48%] bg-pistachio border border-spinach rounded mt-3.5 cursor-pointer">
                                                    <span style={{ letterSpacing: -0.24 }}
                                                        className="text-13 font-bold text-cavolo px-2 py-2.5 text-center not-heading-font">
                                                        {t('yourImpact.i’d-like-to-pledge-something-else')}
                                                    </span>
                                                </div>
                                            )}
                                        </div>

                                        {/* {user?.company?.showAimForZeroOnTravelImpactScreen === 'Show' && ( */}
                                        <div className="flex mt-[22px]">
                                            <div className="flex items-center">
                                                <button onClick={onOffset}
                                                    className="border border-spinach bg-pistachio rounded">
                                                    <h1 className="py-1.5 px-4 text-12 font-bold text-cavolo not-heading-font">
                                                        {t('yourImpact.offset')}
                                                    </h1>
                                                </button>

                                                <h1 className="mx-1.5 text-12 font-normal text-black tracking-tight">
                                                    {t('yourImpact.or')}
                                                </h1>

                                                <button onClick={onRemove}
                                                    className="border border-spinach bg-pistachio rounded">
                                                    <h1 className="py-1.5 px-4 text-12 font-bold text-cavolo not-heading-font">
                                                        {t('yourImpact.remove')}
                                                    </h1>
                                                </button>

                                                <h1 className="text-12 font-normal text-black tracking-tight ml-1.5">
                                                    {t('yourImpact.your-emissions-instead')}
                                                </h1>
                                            </div>
                                        </div>
                                        {/* )} */}

                                        <PlanJourneyTransitItem setLegsEndPlaceNames={setOutboundLegsEndPlaceNames} planJourneyData={commuteSave} disableDropdown item={commuteSave.outboundTransitJourney} name="Public transport" googleMapTravelMode="transit" />

                                        <div className="hidden">
                                            <PlanJourneyTransitItem setLegsEndPlaceNames={setReturnLegsEndPlaceNames} planJourneyData={commuteSave} disableDropdown item={commuteSave.returnTransitJourney} name="Public transport" googleMapTravelMode="transit" />
                                        </div>
                                    </ShowWithAnimation>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>

                <div
                    className={`fixed bottom-0 ${isEmbedded ? 'h-[120px]' : 'h-[112px]'} bg-pistachio w-full duration-100`}
                    style={{ boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.15)' }}>
                    <div
                        className="mx-auto flex max-mobile:flex-col max-w-[848px] relative h-full px-[16px] mobile:px-[42px]">
                        <div className="flex-1 mt-7 mobile:mr-[40px] mr-[16px]" />

                        <div className={`${isEmbedded ? 'mb-4' : ''} mobile:ml-8 mobile:w-[180px] relative`}>
                            <Button
                                onClick={onSubmit}
                                className="absolute bottom-8 duration-100 not-heading-font"
                                text={t('global.continue')} />
                        </div>
                    </div>
                </div>

                {showRemoveModal && (
                    <RemoveYourEmissionsModal onClose={() => setShowRemoveModal(false)} />
                )}

                {showOffsetModal && (
                    <OffsetYourEmissionsModal onClose={() => setShowOffsetModal(false)} />
                )}

                {firstTicketModal && (
                    <TicketModal
                        prizeDraw
                        width
                        descriptionFirst={t('prizeDraw.thank-you-for-helping-us-understand-our-environmental-impact')}
                        descriptionSecond=" "
                        onClose={() => setFirstTicketModal(false)}
                        title={t('prizeDraw.thank-you')} />
                )}

                <QuizQuitOverlayModal
                    description={user?.company?.gamification ? t('log.quit-description-is-switched-on-gamification') : t('log.quit-description-is-switched-off-gamification')}
                    isShown={showQuizQuitOverlayModal}
                    onClose={() => navigate('/me/impact')}
                    onContinue={() => setShowQuizQuitOverlayModal(false)} />

                <PledgeSuccessModal
                    title={commuteSave?.game?.isFuture
                        ? t('yourImpact.are-you-sure-to-replace-your-journey-to-public-transport')
                        : t('yourImpact.thank-you-for-pledging-to-reduce-your-emissions-by-kg-next-time').replace('[X]', String(yourEmissionByKg))}
                    showModal={showModal}
                    setShowModal={setShowModal}
                    isFuture={commuteSave?.game?.isFuture}
                    onSubmit={onSubmitPledge}
                    loading={loading}
                />
            </div>
        </Layout>
    )
}

// import React, { useContext, useEffect, useState } from 'react'
// import { useTranslation } from 'react-i18next'
// import { useDispatch, useSelector } from 'react-redux'
// import { useLocation, useNavigate } from 'react-router-dom'
// import ReactGA from 'react-ga4'
// import Layout from '../../components/layout/Layout'
// import Button from '../../components/common/Button'
// import { Context } from '../../components/common/BaseContext'
// import { getUser, getWhiteLabelingList } from '../../state/user/selectors'
// import { commuteSaveList } from '../../state/travel/selectors'
// import { getDate } from '../../utils/date'
// import { readableCO2 } from '../../utils/helpers'
// import OffsetYourEmissionsModal from '../../components/travel/OffsetYourEmissionsModal'
// import RemoveYourEmissionsModal from '../../components/travel/RemoveYourEmissionsModal'
// import { ArrowTravel, DismissIcon } from '../../components/common/Svg'
// import QuizQuitOverlayModal from '../../components/quiz/QuizQuitOverlayModal'
// import SponsoredByBlock from '../../components/SponsoredByBlock'
// import { getHomePageLists } from '../../state/home/selectors'
// import { removeOrOffset } from '../../state/travel/actions'
// import { fetchWinnersPrice } from '../../state/prize/actions'
//
// export default function YourImpact() {
//     const dispatch = useDispatch()
//     const navigate = useNavigate()
//     const [t] = useTranslation('global')
//     const { pathname } = useLocation()
//     const { navbarHeight } = useContext(Context)
//     const { isEmbedded } = useContext(Context)
//     const { whiteLabeling } = useSelector(getWhiteLabelingList)
//     const { commuteSave } = useSelector(commuteSaveList)
//     const { user } = useSelector(getUser)
//     const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false)
//     const [showOffsetModal, setShowOffsetModal] = useState<boolean>(false)
//     const [showQuizQuitOverlayModal, setShowQuizQuitOverlayModal] = useState<boolean>(false)
//     const totalCeo2Kg = commuteSave?.totalCeo2Kg || 0
//     const averageCeo2Kg = (commuteSave?.totalCeo2Kg === commuteSave?.averageCeo2Kg ? 0.001 : 0) + (commuteSave?.averageCeo2Kg || 0)
//     const yourFootprint = parseFloat(readableCO2(totalCeo2Kg) as string)
//     const fanAverage = parseFloat(readableCO2(averageCeo2Kg) as string)
//
//     const largeCircleRadius = 100
//     const largerCircleArea = Math.PI * largeCircleRadius ** 2
//     const smallerCircleArea = yourFootprint ? ((fanAverage > yourFootprint ? yourFootprint : fanAverage) / (yourFootprint > fanAverage ? yourFootprint : fanAverage)) * largerCircleArea : 0
//     const smallerCircleRadius = Math.sqrt(smallerCircleArea / Math.PI)
//
//     const percentageDecrease = 100 - ((yourFootprint / fanAverage) * 100)
//     const percentageIncrease = ((yourFootprint * 100) / fanAverage) - 100
//
//     const radiusYourFootPrint = yourFootprint > fanAverage ? largeCircleRadius : smallerCircleRadius
//     const radiusFanAverage = fanAverage > yourFootprint ? largeCircleRadius : smallerCircleRadius
//
//     const onSubmit = () => {
//         if (!user?.company?.prizeDrawEntryAtTheEndOfTravelLogging) {
//             dispatch(fetchWinnersPrice)
//
//             if (user?.company?.anonymousMode) {
//                 navigate('/')
//                 return
//             }
//             navigate('/me/impact')
//         } else {
//             navigate('/log/prize-draw')
//         }
//     }
//
//     useEffect(() => {
//         window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
//     }, [pathname])
//
//     useEffect(() => {
//         ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Your impact' })
//     }, [])
//
//     const { sponsorshipBlocks } = useSelector(getHomePageLists)
//     const sponsoredBlock = sponsorshipBlocks.find((i) => i.siteSection === 'Log')
//
//     const onRemove = () => {
//         setShowRemoveModal(true)
//
//         dispatch(removeOrOffset({
//             commute: commuteSave?.id,
//             removeStatus: 'clicked',
//         }))
//     }
//
//     const onOffset = () => {
//         setShowOffsetModal(true)
//
//         dispatch(removeOrOffset({
//             commute: commuteSave?.id,
//             offsetStatus: 'clicked',
//         }))
//     }
//
//     function capitalizeFirstLetter(text: string | undefined | null) {
//         return text ? text.charAt(0).toUpperCase() + text.slice(1) : ''
//     }
//
//     return (
//         <Layout showFooter={false} maxWidth={false}>
//             <div className="w-full min-h-screen">
//                 <style>
//                     {`
//                         .dashed {
//                             border-bottom: dashed 1.5px ${whiteLabeling?.cavolo};
//                         }
//                         .line-vertical {
//                             position: relative;
//                         }
//                         .line-vertical {
//                             border-left: solid 1.5px ${whiteLabeling?.cavolo};
//                         }
//                         .arrow-down:before {
//                             content: "";
//                             position: absolute;
//                             left: -5.5px;
//                             bottom: -10px;
//                             width: 0;
//                             height: 0;
//                             border-left: 5px solid transparent;
//                             border-right: 5px solid transparent;
//                             border-top: 10px solid ${whiteLabeling?.cavolo};
//                         }
//                     `}
//                 </style>
//
//                 {sponsoredBlock && <div className="mb-3 mobile:hidden"><SponsoredByBlock item={sponsoredBlock} /></div>}
//
//                 <div>
//                     <div style={{ marginTop: navbarHeight }} className="fixed top-0 left-0 w-full z-10 bg-white">
//                         <div className="flex justify-between items-center pt-[30px] pr-2 mobile:mx-[42px] mx-4">
//                             <div onClick={() => navigate(-1)} className="flex justify-end cursor-pointer">
//                                 <ArrowTravel width={22} height={22} />
//                             </div>
//
//                             <div onClick={() => setShowQuizQuitOverlayModal(true)}
//                                 className="flex justify-end cursor-pointer">
//                                 <DismissIcon width={18} height={18} />
//                             </div>
//                         </div>
//                     </div>
//
//                     <div style={{ marginTop: navbarHeight }}
//                         className="h-screen flex flex-col items-center mt-[12px] mobile:px-[42px] px-[16px]">
//                         <div className="max-w-[600px] w-full mt-2 pb-[300px]">
//                             <h1 className="heading-uppercase text-cavolo text-22 tracking-tight font-semibold">
//                                 {t('me.your-impact')}
//                             </h1>
//
//                             <h1 className="text-grey01">
//                                 {commuteSave?.startPlaceName?.slice(0, commuteSave?.startPlaceName?.indexOf(','))} {t('global.to')} {commuteSave?.endPlaceName?.slice(0, commuteSave?.endPlaceName?.indexOf(','))}, {t('yourImpact.return')}, {commuteSave?.game?.date ? getDate(commuteSave?.game?.date || '') : getDate(String(commuteSave?.date))}
//                             </h1>
//
//                             <div className="flex items-end">
//                                 <div className="flex flex-col items-center mt-[25px]">
//                                     <svg width={200} height={200}
//                                         className="mt-flex flex-col items-center[31px] relative">
//                                         <circle cx={100} cy={100}
//                                             r={totalCeo2Kg > averageCeo2Kg ? radiusYourFootPrint : radiusFanAverage}
//                                             fill={totalCeo2Kg > averageCeo2Kg ? whiteLabeling?.lime : whiteLabeling?.spinach} />
//
//                                         <clipPath id="circle-1">
//                                             <text x={100} y={88} textAnchor="middle" dy=".3em" fontSize={16}
//                                                 fontWeight="700">
//                                                 {totalCeo2Kg > averageCeo2Kg ? `${yourFootprint}kg CO2e` : `${fanAverage}kg CO2e`}
//                                             </text>
//
//                                             <text x={100} y={112} textAnchor="middle" dy=".3em" fontSize={16}
//                                                 fontWeight="400">
//                                                 {totalCeo2Kg > averageCeo2Kg
//                                                     ? t('yourImpact.your-footprint')
//                                                     : t('yourImpact.fan-average').replace('[Fan]', capitalizeFirstLetter(user?.company?.impactFanText || 'Fan'))}
//                                             </text>
//                                         </clipPath>
//
//                                         <g clipPath="url(#circle-1)">
//                                             <circle cx={100} cy={100} r={1000}
//                                                 fill={totalCeo2Kg < averageCeo2Kg ? whiteLabeling?.cavolo : whiteLabeling?.savoy} />
//                                             <circle cx={100} cy={100}
//                                                 r={totalCeo2Kg > averageCeo2Kg ? radiusYourFootPrint : radiusFanAverage}
//                                                 fill={totalCeo2Kg > averageCeo2Kg ? whiteLabeling?.cavolo : whiteLabeling?.savoy} />
//                                         </g>
//                                     </svg>
//
//                                     <svg width={200} height={200} className="relative">
//                                         <circle cx={100} cy={100}
//                                             r={averageCeo2Kg > totalCeo2Kg ? radiusYourFootPrint : radiusFanAverage}
//                                             fill={averageCeo2Kg > totalCeo2Kg ? whiteLabeling?.lime : whiteLabeling?.spinach} />
//
//                                         {smallerCircleRadius === 0 ? null : (
//                                             <>
//                                                 <clipPath id="circle-2">
//                                                     <text x={100} y={88} textAnchor="middle" dy=".3em" fontSize={16}
//                                                         fontWeight="700">
//                                                         {averageCeo2Kg > totalCeo2Kg ? `${yourFootprint}kg CO2e` : `${fanAverage}kg CO2e`}
//                                                     </text>
//
//                                                     <text x={100} y={112} textAnchor="middle" dy=".3em" fontSize={16}
//                                                         fontWeight="400">
//                                                         {averageCeo2Kg > totalCeo2Kg
//                                                             ? t('yourImpact.your-footprint')
//                                                             : t('yourImpact.fan-average').replace('[Fan]', capitalizeFirstLetter(user?.company?.impactFanText || 'Fan'))}
//                                                     </text>
//                                                 </clipPath>
//
//                                                 <g clipPath="url(#circle-2)">
//                                                     <circle cx={100} cy={100} r={1000}
//                                                         fill={totalCeo2Kg > averageCeo2Kg ? whiteLabeling?.cavolo : whiteLabeling?.savoy} />
//                                                     <circle cx={100} cy={100}
//                                                         r={averageCeo2Kg > totalCeo2Kg ? radiusYourFootPrint : radiusFanAverage}
//                                                         fill={averageCeo2Kg > totalCeo2Kg ? whiteLabeling?.cavolo : whiteLabeling?.savoy} />
//                                                 </g>
//                                             </>
//                                         )}
//                                     </svg>
//
//                                     <div className="flex flex-col items-center pl-2.5 w-full">
//                                         <h1 className="font-bold text-14 text-cavolo not-heading-font">0kg CO2e</h1>
//
//                                         {smallerCircleRadius === 0 && (
//                                             <h1 className="font-normal text-14 text-cavolo">{t('yourImpact.your-footprint')}</h1>
//                                         )}
//
//                                         <div className="flex items-end ml-[100px]">
//                                             <div className="flex items-center">
//                                                 <div
//                                                     className={`w-[6px] h-[6px] ${smallerCircleRadius === 0 ? 'bg-raspberry' : 'bg-cavolo'} rounded-full`} />
//
//                                                 <div className="w-[110px] dashed" />
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//
//                                 <div>
//                                     <div className="w-[50%] ml-[-50%] dashed" />
//
//                                     <div
//                                         className={`w-[155px] ${smallerCircleRadius === 0 ? 'h-[334px]' : 'h-[192px]'} pl-1.5 flex flex-col items-center ${smallerCircleRadius === 0 ? 'mb-[13px]' : 'mb-[9px]'}  justify-center line-vertical arrow-down`}>
//                                         {yourFootprint === fanAverage ? (
//                                             <h1 className="text-center text-12 font-normal text-cavolo mt-1">
//                                                 {t('log.your-impact-is-the-same-as-the-average-fan-attending-this-event').replace('[fan]', user?.company.impactFanText ? user?.company?.impactFanText : '')}
//                                             </h1>
//                                         ) : (
//                                             <div>
//                                                 <h1 className="text-center font-bold text-14 text-cavolo">
//                                                     {smallerCircleRadius === 0 ? t('yourImpact.amazing') : `${radiusFanAverage === 100 ? t('global.well-done') : ''}`}
//                                                 </h1>
//
//                                                 {user?.company?.travelTrackingType === 'event' ? (
//                                                     <h1 className="text-center text-12 font-normal text-cavolo mt-1">
//                                                         {smallerCircleArea === 0 ? t('yourImpact.you-had-no-travel-emissions-when-attending-this-event') : `${t('yourImpact.your-impact-is')} ${fanAverage > yourFootprint ? `${percentageDecrease.toFixed(0)}% ${t('yourImpact.less')}` : `${percentageIncrease.toFixed(0)}% ${t('yourImpact.more')}`} ${t('yourImpact.than-the-average-fan-attending-this-event')}`}
//                                                     </h1>
//                                                 ) : (
//                                                     <h1 className="text-center text-12 font-normal text-cavolo mt-1">
//                                                         {smallerCircleArea === 0 ? t('yourImpact.you-had-no-travel-emissions-when-attending-this-event') : `${t('yourImpact.your-impact-is')} ${fanAverage > yourFootprint ? `${percentageDecrease.toFixed(0)}% ${t('yourImpact.less')}` : `${percentageIncrease.toFixed(0)}% ${t('yourImpact.more')}`} ${t('yourImpact.than-the-average-journey-within-your-organisation')}`}
//                                                     </h1>
//                                                 )}
//                                             </div>
//
//                                         )}
//                                     </div>
//
//                                     {smallerCircleRadius !== 0 && (
//                                         <div className="w-[50%] ml-[-50%] dashed" />
//                                     )}
//
//                                     {smallerCircleRadius !== 0 && user?.company?.showAimForZeroOnTravelImpactScreen === 'Show' && (
//                                         <div
//                                             className={`w-[160px] h-[112px] flex flex-col items-center pl-2 mb-3 pb-3 pt-1 ${smallerCircleRadius !== 0 && 'line-vertical arrow-down'}`}>
//                                             <h1 className="not-heading-font font-bold text-14 text-cavolo">
//                                                 {t('yourImpact.aim-for-zero')}
//                                             </h1>
//
//                                             {radiusFanAverage === 100 ? (
//                                                 <h1 className="text-center text-12 font-normal text-cavolo mt-1">
//                                                     {t('yourImpact.go-further-and-offset-or-remove-your-emissions')}
//                                                 </h1>
//                                             ) : (
//                                                 <h1 className="text-center text-12 font-normal text-cavolo mt-1">
//                                                     {t('yourImpact.offset-or-remove-your-emissions')}
//                                                 </h1>
//                                             )}
//
//                                             <div className="flex mt-2">
//                                                 <button onClick={onRemove}
//                                                     className="border border-spinach bg-pistachio rounded mr-2.5">
//                                                     <h1 className="not-heading-font py-1.5 px-2.5 text-12 font-bold text-cavolo">
//                                                         {t('yourImpact.remove')}
//                                                     </h1>
//                                                 </button>
//
//                                                 <button onClick={onOffset}
//                                                     className="border border-spinach bg-pistachio rounded">
//                                                     <h1 className="not-heading-font py-1.5 px-2.5 text-12 font-bold text-cavolo">
//                                                         {t('yourImpact.offset')}
//                                                     </h1>
//                                                 </button>
//                                             </div>
//                                         </div>
//                                     )}
//
//                                     {(user?.company?.showAimForZeroOnTravelImpactScreen !== 'Show' && smallerCircleRadius !== 0) && (
//                                         <div
//                                             className="w-[160px] h-[112px] flex flex-col items-center pl-2 mb-3 pb-3 pt-1 line-vertical arrow-down" />
//                                     )}
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//
//                     <div className={`fixed bottom-0 ${isEmbedded ? 'h-[120px]' : 'h-[112px]'} bg-pistachio w-full duration-100`} style={{ boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.15)' }}>
//                         <div
//                             className="mx-auto flex max-mobile:flex-col max-w-[848px] relative h-full px-[16px] mobile:px-[42px]">
//                             <div className="flex-1 mt-7 mobile:mr-[40px] mr-[16px]" />
//
//                             <div className={`${isEmbedded ? 'mb-4' : ''} mobile:ml-8 mobile:w-[180px] relative`}>
//                                 <Button
//                                     onClick={onSubmit}
//                                     className="absolute bottom-8 duration-100 not-heading-font"
//                                     text={t('global.continue')} />
//                             </div>
//                         </div>
//                     </div>
//
//                     {showRemoveModal && (
//                         <RemoveYourEmissionsModal onClose={() => setShowRemoveModal(false)} />
//                     )}
//
//                     {showOffsetModal && (
//                         <OffsetYourEmissionsModal onClose={() => setShowOffsetModal(false)} />
//                     )}
//
//                     <QuizQuitOverlayModal
//                         description={user?.company?.gamification ? t('log.quit-description-is-switched-on-gamification') : t('log.quit-description-is-switched-off-gamification')}
//                         isShown={showQuizQuitOverlayModal}
//                         onClose={() => navigate('/me/impact')}
//                         onContinue={() => setShowQuizQuitOverlayModal(false)} />
//                 </div>
//             </div>
//         </Layout>
//     )
// }
