import React, { ReactElement, useEffect, useState } from 'react'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { createColourStyles, createDictionaryWithParents } from '../../utils/organisationUnit'
import { getOrganisationalUnitsList, getUser, getWhiteLabelingList } from '../../state/user/selectors'
import { ShieldIcon } from '../common/Svg'
import { getLeaderboard } from '../../state/leaderboard/selectors'
import { IOrganisationUnits } from '../../types/data'
import { fetchOrganisationalUnitsList, fetchUpdateUserSetting, fetchUser } from '../../state/user/actions'
import { Colors } from '../../themes'
import Button from '../common/Button'
import SecondButton from '../common/SecondButton'

interface IProps {
    setScheduleShowModal?: (value: boolean) => void;
    onClose?: () => void
    isModal?: boolean
}

export default function OrganisationUnitBody({ setScheduleShowModal, onClose, isModal }: IProps) {
    const dispatch = useDispatch()
    const { user } = useSelector(getUser)
    const [t] = useTranslation('global')
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const { leaderboard } = useSelector(getLeaderboard)
    const { organisationUnitsSearchResults } = useSelector(getOrganisationalUnitsList)
    const [clearInput, setClearInput] = useState<number>(-1)
    const [selectedOrganisationUnit, setSelectedOrganisationUnit] = useState<IOrganisationUnits>()
    const [loading, setLoading] = useState<boolean>(false)
    const [search, setSearch] = useState<Date | string | any>('')
    const [level, setLevel] = useState<number | string | undefined>()
    const imageOrganisation = leaderboard?.leaderboards?.find((item) => item?.entry?.name === user?.company?.organisationalUnitName)
    const debouncedSearch = useDebounce(search, 500)

    const options = organisationUnitsSearchResults?.map((item: any) => (
        {
            id: item?.id,
            value: item.name,
            level: item?.level,
            label:
    <div className="flex items-center">
        {item?.image !== null ? (
            <img className="w-[26px] h-[28px] object-cover" src={item?.image} alt="" />
        ) : (
            imageOrganisation?.entry?.image
                ? <img className="w-[26px] h-[28px] object-cover" src={imageOrganisation?.entry?.image} alt="" />
                : <ShieldIcon />
        )}

        <span className="font-bold not-heading-font text-14 ml-[13px]">{item?.name}</span>
    </div>,
            color: whiteLabeling?.black || 'rgba(0, 0, 0, 1)',
            item,
        }
    ))

    const result = createDictionaryWithParents(selectedOrganisationUnit || user?.organisationUnit)

    const handleInputChange = (value: string, l?: number | string | undefined) => {
        setLevel(l)
        setSearch(value)
    }

    const handleSelectChange = (selectedOption: any) => {
        setSelectedOrganisationUnit(selectedOption?.item)
    }

    const activeOption: {[key: string]: {
        label: ReactElement,
        id: string,
        value: string,
        level: string,
        color: string
    }} = Object.entries(result).reduce((object, [key, item]) => (
        {
            ...object,
            [item.level || key]: {
                label:
    <div className="flex items-center">
        {item?.image !== null ? (
            <img className="w-[26px] h-[28px] object-cover" src={item?.image} alt="" />
        ) : (
            imageOrganisation?.entry?.image
                ? <img className="w-[26px] h-[28px] object-cover" src={imageOrganisation?.entry?.image} alt="" />
                : <ShieldIcon />
        )}

        <span className="font-bold not-heading-font text-14 ml-[13px]">{item?.name}</span>
    </div>,
                id: item?.id,
                value: item.name,
                level: item?.level,
                color: '',
            },
        }
    ), {})

    const onSubmit = async () => {
        const data = { organisationUnit: selectedOrganisationUnit?.id || 0 }

        if (setScheduleShowModal) {
            setScheduleShowModal?.(true)
        }

        setLoading(true)
        await dispatch(fetchUpdateUserSetting(data))
        setLoading(false)

        if (onClose) {
            onClose()
        }
        if (!user?.organisationUnit?.name) dispatch(fetchUser)
    }

    function useDebounce(value: string, delay: number) {
        const [debouncedValue, setDebouncedValue] = useState(value)

        useEffect(() => {
            const handler = setTimeout(() => {
                setDebouncedValue(value)
            }, delay)

            return () => {
                clearTimeout(handler)
            }
        }, [value, delay])

        return debouncedValue
    }

    useEffect(() => {
        if (debouncedSearch) dispatch(fetchOrganisationalUnitsList(debouncedSearch, level))
    }, [debouncedSearch, dispatch, level])

    const disabledStyles = {
        backgroundColor: Colors.grey03,
        borderColor: Colors.grey03,
        color: Colors.grey02,
        boxShadow: '2px 2px 0px rgba(0, 0, 0, 0.15)',
        transitionDuration: '0.1s',
    }

    // const selectProps = {
    //     value: clearInput ? null : name,
    //     isClearable: false,
    //     onMenuOpen: () => setClearInput(true),
    //     onMenuClose: () => setClearInput(false),
    //     maxMenuHeight: 200,
    //     options,
    //     styles: colourStyles,
    //     onChange: handleSelectChange,
    // }

    return (
        <div>
            {user?.company?.organisationalUnitLowestMandatoryItem?.level && (
                <div>
                    <h1 className="line text-14 tracking-tight font-medium text-black">
                        {t('account.change-my-name').replace('[name]', user?.company?.organisationalUnitLowestMandatoryItem?.name || 'club')}
                    </h1>

                    <div className="mt-1.5">
                        <Select
                            isClearable={false}
                            maxMenuHeight={200}
                            value={user?.company?.organisationalUnitLowestMandatoryItem?.level === clearInput ? null : activeOption[user?.company?.organisationalUnitLowestMandatoryItem?.level || user?.organisationUnit?.level || 0]}
                            onMenuOpen={() => setClearInput(user?.company?.organisationalUnitLowestMandatoryItem?.level || 0)}
                            onMenuClose={() => setClearInput(-1)}
                            options={user?.company?.organisationalUnitAffiliation ? options?.filter((l) => user?.company?.organisationalUnitLowestMandatoryItem?.level === l?.level) : options}
                            placeholder={t('account.search')}
                            styles={createColourStyles(whiteLabeling)}
                            isSearchable
                            onChange={handleSelectChange}
                            onInputChange={(value) => handleInputChange(value, user?.company?.organisationalUnitLowestMandatoryItem?.level)}
                            noOptionsMessage={() => t('account.type-the-first-three-characters')}
                        />
                    </div>
                </div>
            )}

            {user?.company?.organisationalUnitNotMandatoryItems?.map((item, index) => {
                const itemKey = `item-${index}`

                return (
                    <div className="mt-2" key={itemKey}>
                        <h1 className="line text-14 tracking-tight font-medium text-black mb-1">
                            {t('account.change-my-name').replace('[name]', item?.name || 'club')}
                        </h1>

                        <Select
                            isClearable={false}
                            maxMenuHeight={200}
                            value={((selectedOrganisationUnit?.level || -1) === (user?.company?.organisationalUnitLowestMandatoryItem?.level || 0) ? null : item?.level === clearInput ? null : activeOption[item.level || 0]) || null}
                            onMenuOpen={() => setClearInput(item?.level || 0)}
                            onMenuClose={() => setClearInput(-1)}
                            placeholder={t('account.search')}
                            options={options?.filter((l) => item?.level === l?.level)}
                            styles={createColourStyles(whiteLabeling)}
                            isSearchable
                            onChange={handleSelectChange}
                            onInputChange={(value) => handleInputChange(value, item?.level)}
                            noOptionsMessage={() => t('account.type-the-first-three-characters')}
                        />
                    </div>
                )
            })}

            {isModal ? (
                <Button
                    loading={loading}
                    onClick={onSubmit}
                    style={!selectedOrganisationUnit ? disabledStyles : undefined}
                    disabled={!selectedOrganisationUnit}
                    text={t('global.save')}
                    className={`${selectedOrganisationUnit && 'cursor-pointer'}`} />
            ) : (
                <SecondButton
                    loading={loading}
                    onClick={onSubmit}
                    className="w-full mt-[22px] bg-rocket h-[48px] font-semibold border rounded-md text-14 text-pistachio"
                    text={t('global.save')} />
            )}
        </div>
    )
}
