import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import Dashboard from './pages/Dashboard'
import Perks from './pages/perk/Perks'
import Recipes from './pages/recipe/Recipes'
import Discover from './pages/Discover/Discover'
import Challenge from './pages/challenge/Challenge'
import Login from './pages/Login'
import './static/index.css'
import Register from './pages/Register'
import AuthNavigator from './pages/AuthNavigator'
import ResetPasswordEmail from './pages/ResetPasswordEmail'
import { persistor, store } from './state'
import ResetLinkSent from './pages/ResetLinkSent'
import ResetPassword from './pages/ResetPassword'
import PerksBookmark from './pages/perk/PerksBookmark'
import RecipeBookmark from './pages/recipe/RecipeBookmark'
import ArticleBookmark from './pages/Discover/ArticleBookmark'
import PerkByType from './pages/perk/PerkByType'
import PerkDetail from './pages/perk/PerkDetail'
import PerkFilterByTag from './pages/perk/PerkFilterByTag'
import BrowseByTopicList from './pages/Discover/BrowseByTopicList'
import PerkViewAll from './pages/perk/PerkViewAll'
import PerkBySearch from './pages/perk/PerkFilterBySearch'
import RecipesBySearch from './pages/recipe/RecipeFilterBySearch'
import DiscoverSearch from './pages/Discover/DiscoverSearch'
import ArticleViewAll from './pages/Discover/ArticleViewAll'
import RecipesViewAll from './pages/recipe/RecipesViewAll'
import RecipeDetail from './pages/recipe/RecipeDetail'
import ArticleDetail from './pages/Discover/ArticleDetail'
import ChallengeDetail from './pages/challenge/ChallengeDetail'
import Badges from './pages/badge/Badges'
import Progress from './pages/progress/Progress'
import Leaderboard from './pages/leaderboard/Leaderboard'
import BaseContextWrapper from './components/common/BaseContext'
import RecipeByCategory from './pages/recipe/RecipeByCategory'
import RecipeFilterByTag from './pages/recipe/RecipeFilterByTag'
import TermsAndConditions from './pages/TermsAndConditions'
import FAQ from './pages/account/FAQ'
import AccountOverview from './pages/account/AccountOverview'
import YourInfo from './pages/account/YourInfo'
import ChangePassword from './pages/account/ChangePassword'
import DeleteAccount from './pages/account/DeleteAccount'
import PrivacyPolicy from './pages/PrivacyPolicy'
import PrizeDraw from './pages/account/PrizeDraw'
import HomeEmployeeRecipeViewAll from './pages/HomeEmployeeRecipeViewAll'
import Quiz from './pages/quiz/Quiz'
import CompanyArticleDetail from './pages/Discover/specificContent/CompanyArticleDetail'
import News from './pages/News'
import PledgesDetail from './pages/pledges/PledgesDetail'
import PledgesList from './pages/pledges/PledgesList'
import PledgesViewAll from './pages/pledges/PledgesViewAll'
import Travel from './pages/travel/Travel'
import TrackTravelFan from './pages/travel/TrackTravelFan'
import TrackTravelModality from './pages/travel/TrackTravelModality'
import LegTravelStartEnd from './pages/travel/LegTravelStartEnd'
import TrackTravelOverview from './pages/travel/TrackTravelOverview'
import TrackTravelRecentRoutes from './pages/travel/TrackTravelRecentRoutes'
import TrackTravelSavedRoutes from './pages/travel/TrackTravelSavedRoutes'
import TrackTravelSavedTripDate from './pages/travel/TrackTravelSavedTripDate'
import FanAndEmployee from './components/FanAndEmployee'
import TrackTravelReturn from './pages/travel/TrackTravelReturn'
import HomeEmployeePerkViewAll from './pages/HomeEmployeePerkViewAll'
import RewardsDetail from './pages/rewards/RewardsDetail'
import SSOLogin from './pages/SSOLogin'
import HowToEarnPoints from './pages/account/HowToEarnPoints'
import Log from './pages/Log'
import Authorize from './pages/Authorize'
import LogTravelStartEnd from './pages/travel/LogTravelStartEnd'
import LandingPage from './pages/LandingPage'
import LogTravelCarDetails from './pages/travel/LogTravelCarDetails'
import Preferences from './pages/Preferences'
import PlanJourney from './pages/PlanJourney'
import Referral from './pages/referral/Referral'
import PlanJourneyResult from './pages/PlanJourneyResult'
import NotFound404Page from './pages/NotFound404Page'
import TravelPrizeDraw from './pages/travel/TravelPrizeDraw'
import YourImpact from './pages/travel/YourImpact'
import OrganisationUnit from './pages/OrganisationUnit'
import ReferralAuthorize from './components/ReferralAuthorize'
import TravelArticles from './pages/travel/TravelArticles'
import SavedPlaces from './pages/SavedPlaces'
import Schedule from './pages/schedule/Schedule'
import OvernightStay from './pages/travel/OvernightStay'
import LogTravelDate from './pages/travel/LogTravelDate'
import LastMultiDaySection from './pages/travel/LastMultiDaySection'
import Survey from './pages/Survey'
import LogRedirect from './pages/LogRedirect'
import LogTravelAI from './pages/travel/LogTravelAI'
import LogTravelAIVoiceListening from './pages/travel/LogTravelAIVoiceListening'
import LogTravelAIText from './pages/travel/LogTravelAIText'

export default function App() {
    return (
        <BrowserRouter>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BaseContextWrapper>
                        <Routes>
                            {/* AUTH */}
                            <Route path="/" element={<FanAndEmployee />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/sso-login" element={<SSOLogin />} />
                            <Route path="/register" element={<Register />} />
                            <Route path="/auth" element={<AuthNavigator />} />
                            <Route path="/authorize" element={<Authorize />} />
                            <Route path="/refer/:referId" element={<ReferralAuthorize />} />
                            <Route path="/reset-password" element={<ResetPasswordEmail />} />
                            <Route path="/reset-link-sent" element={<ResetLinkSent />} />
                            <Route path="/reset-password/:code" element={<ResetPassword />} />

                            {/* DASHBOARD */}
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/dashboard/swaps-section/:slot/view-all" element={<HomeEmployeePerkViewAll />} />
                            <Route path="/dashboard/recipes-section/:slot/view-all" element={<HomeEmployeeRecipeViewAll />} />

                            {/* PLAN */}
                            <Route path="/plan/plan-journey" element={<PlanJourney />} />
                            <Route path="/plan/plan-journey-result" element={<PlanJourneyResult />} />

                            {/* TRAVEL ARTICLES */}
                            <Route path="/lower/travel-articles" element={<TravelArticles />} />

                            {/* PERKS */}
                            <Route path="/lower/swaps" element={<Perks />} />
                            <Route path="/lower/swaps/swaps-bookmark" element={<PerksBookmark />} />
                            <Route path="/lower/swaps/swap-by-type/:perkId" element={<PerkByType />} />
                            <Route path="/lower/swaps/swap-filter-by-tag" element={<PerkFilterByTag />} />
                            <Route path="/lower/swaps/search/:search" element={<PerkBySearch />} />
                            <Route path="/lower/swaps/:detailId" element={<PerkDetail />} />
                            <Route path="/lower/swaps-section/:slot/view-all" element={<PerkViewAll />} />

                            {/* CHALLENGES */}
                            <Route path="/lower/pledges" element={<Challenge />} />
                            <Route path="/lower/pledges/challenge-detail/:challengeId" element={<ChallengeDetail />} />

                            {/* PLEDGES */}
                            <Route path="/lower/pledges/pledges-detail/:pledgeId" element={<PledgesDetail />} />
                            <Route path="/lower/pledges/pledges-list" element={<PledgesList />} />
                            <Route path="/dashboard/pledges-section/:slot/view-all" element={<PledgesViewAll />} />

                            {/* DISCOVER */}
                            <Route path="/learn/articles" element={<Discover />} />
                            <Route path="/learn/articles/:articleId/:topicId?" element={<ArticleDetail />} />
                            <Route path="/learn/articles/article-bookmark" element={<ArticleBookmark />} />
                            <Route path="/learn/articles/browse-by-topic-list/:topicId" element={<BrowseByTopicList />} />
                            <Route path="/learn/articles/search/:search" element={<DiscoverSearch />} />
                            <Route path="/learn/articles/section/:slot" element={<ArticleViewAll />} />

                            <Route path="/learn/organisation-news/:articleId" element={<CompanyArticleDetail />} />

                            {/* RECIPES */}
                            <Route path="/lower/recipes" element={<Recipes />} />
                            <Route path="/lower/recipes/recipe-bookmark" element={<RecipeBookmark />} />
                            <Route path="/lower/recipes-section/:slot/view-all" element={<RecipesViewAll />} />
                            <Route path="/lower/recipes/search/:search" element={<RecipesBySearch />} />
                            <Route path="/lower/recipes/:recipeId" element={<RecipeDetail />} />
                            <Route path="/lower/recipes/category/:categoryId" element={<RecipeByCategory />} />
                            <Route path="/lower/recipes/filter-by-tag" element={<RecipeFilterByTag />} />

                            {/* NEWS */}
                            <Route path="/learn/organisation-news" element={<News />} />

                            {/* TRAVEL */}
                            <Route path="/log" element={<Log />} />
                            <Route path="/log-redirect" element={<LogRedirect />} />
                            <Route path="me/impact" element={<Travel />} />
                            <Route path="log/track-travel-fan" element={<TrackTravelFan />} />
                            <Route path="log/track-travel-start-end" element={<LogTravelStartEnd />} />
                            <Route path="log/track-travel-modality" element={<TrackTravelModality />} />
                            <Route path="log/log-travel-car-details" element={<LogTravelCarDetails />} />
                            <Route path="log/track-travel-leg-start-end" element={<LegTravelStartEnd />} />
                            <Route path="log/track-travel-overview" element={<TrackTravelOverview />} />
                            <Route path="log/track-travel-return" element={<TrackTravelReturn />} />
                            {/* <Route path="log/track-travel-impact" element={<TrackTravelImpact />} /> */}
                            <Route path="log/track-travel-recent-routes" element={<TrackTravelRecentRoutes />} />
                            <Route path="log/track-travel-saved-routes" element={<TrackTravelSavedRoutes />} />
                            <Route path="log/track-travel/log-route" element={<TrackTravelSavedTripDate />} />
                            <Route path="log/your-impact" element={<YourImpact />} />
                            <Route path="log/prize-draw" element={<TravelPrizeDraw />} />

                            <Route path="log/overnight-stay" element={<OvernightStay />} />
                            <Route path="log/log-travel-date" element={<LogTravelDate />} />

                            <Route path="log/last-multi-day-section" element={<LastMultiDaySection />} />

                            <Route path="log/log-travel-AI" element={<LogTravelAI />} />
                            <Route path="log/log-travel-AI-voice-listening" element={<LogTravelAIVoiceListening />} />
                            <Route path="log/log-travel-AI-text" element={<LogTravelAIText />} />

                            {/* PROGRESS */}
                            <Route path="/me/progress" element={<Progress />} />

                            {/* LEADERBOARD */}
                            <Route path="/me/leaderboard" element={<Leaderboard />} />

                            {/* BADGES */}
                            <Route path="/me/achievements" element={<Badges />} />
                            <Route path="/me/earn-points" element={<HowToEarnPoints />} />

                            {/* ACCOUNT */}
                            <Route path="/faqs" element={<FAQ />} />
                            <Route path="/account-overview" element={<AccountOverview />} />
                            <Route path="/account/account-settings" element={<YourInfo />} />
                            <Route path="/me/refer-a-friend" element={<Referral />} />
                            <Route path="/account/account-settings/change-password" element={<ChangePassword />} />
                            <Route path="/account/account-settings/delete-account" element={<DeleteAccount />} />
                            <Route path="/account/preferences" element={<Preferences />} />
                            <Route path="/account/organisation-unit" element={<OrganisationUnit />} />
                            <Route path="/account/saved-places" element={<SavedPlaces />} />
                            <Route path="/account/schedule" element={<Schedule />} />
                            <Route path="/prize-draw" element={<PrizeDraw />} />

                            {/* TERMS AND CONDITIONS */}
                            <Route path="/terms-of-services" element={<TermsAndConditions />} />

                            {/* PRIVACY POLICY */}
                            <Route path="/privacy-policy" element={<PrivacyPolicy />} />

                            {/* QUIZ */}
                            <Route path="/quiz/:quizId" element={<Quiz />} />

                            {/* SURVEYS */}
                            <Route path="/survey/:surveyId" element={<Survey />} />

                            {/* REWARDS */}
                            <Route path="/rewards-detail/:rewardsId" element={<RewardsDetail />} />
                            <Route path="/landing/:id" element={<LandingPage />} />
                            <Route path="/404" element={<NotFound404Page />} />
                        </Routes>
                    </BaseContextWrapper>
                </PersistGate>
            </Provider>
        </BrowserRouter>
    )
}
